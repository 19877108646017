import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import { createRoot } from "react-dom/client";
import Login from './pages/Login';
import AdminHome from './pages/admin/AdminHome';
import AdminTests from './pages/admin/AdminTests';
import AdminUsers from './pages/admin/AdminUsers';
import ClinicianHome from './pages/clinician/ClinicianHome';
import ClinicianTests from './pages/clinician/ClinicianTests';
import Test from './pages/tests/Test';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './index.css';

if (sessionStorage.getItem("token") && sessionStorage.getItem("portal") === "admin") {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <AdminHome />
      ),
    },
    {
      path: "/users",
      element: (
        <AdminUsers />
      ),
    },
    {
      path: "/tests",
      element: (
        <AdminTests />
      ),
    },
    {
      path: "/test/:schema/:testId",
      element: (
        <Test />
      ),
    },
  ]);
  
  createRoot(document.getElementById("root")!).render(
    <RouterProvider router={router} />
  );
} else if (sessionStorage.getItem("token") && sessionStorage.getItem("portal") === "clinician") {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <ClinicianHome />
      ),
    },
    {
      path: "/tests",
      element: (
        <ClinicianTests />
      ),
    },
    {
      path: "/test/:schema/:testId",
      element: (
        <Test />
      ),
    },
  ]);
  
  createRoot(document.getElementById("root")!).render(
    <RouterProvider router={router} />
  );
} else {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Login portal="clinician" />
      ),
    },
    {
      path: "/admin",
      element: (
        <Login portal="admin" />
      ),
    },
  ]);
  
  createRoot(document.getElementById("root")!).render(
    <RouterProvider router={router} />
  );
}



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
