import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Sidebar from '../../components/Sidebar';
import { ventrisConfig } from '../../ventris.config';

type TestProps = {
};
  
function Test({}: TestProps) {
  const routeParams = useParams();
  const [test, setTest] = useState({} as any);
  const [schemas, setSchemas] = useState([] as any);
  const [schema, setSchema] = useState({} as any);
  const [categories, setCategories] = useState([] as any);
  const [answers, setAnswers] = useState({} as any);
  const [textAnswers, setTextAnswers] = useState({} as any);
  const [correctAnswers, setCorrectAnswers] = useState(0 as any);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0 as any);
  const [blankAnswers, setBlankAnswers] = useState(0 as any);
  const [cardKey, setCardKey] = useState("" as any);
  const [showCalculations, setShowCalculations] = useState("" as any);
  const [domains, setDomains] = useState({} as any);
  
  useEffect(() => {
    let token = sessionStorage.getItem("token");
    fetch(ventrisConfig.url + '/api/schemas', {
      method: 'get',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
      }
    }).then(function (response) {
      return response.json();
    }).then(async function (data) {
      setSchemas(data);

      for (var i = 0; i < data?.length; i++) {
        if (data[i].name == routeParams.schema) {
          setSchema(data[i]);
          
          let rawQuestions: any = data[i].questions;
          let groupedCategories: any = [];

          for (var j = 0; j < data[i].questions?.length; j++) {
            let categoryIndex: number = -1;
            for (var k = 0; k < groupedCategories.length; k++) {
              if (groupedCategories[k].name == data[i].questions[j].category) {
                categoryIndex = k;
                break;
              }
            }

            if (categoryIndex == -1) {
              categoryIndex = groupedCategories.length;
              groupedCategories.push({
                name: data[i].questions[j].category,
                subcategories: []
              });
            }

            let subcategoryIndex: number = -1;
            for (var k = 0; k < groupedCategories[categoryIndex].subcategories.length; k++) {
              if (groupedCategories[categoryIndex].subcategories[k].name == data[i].questions[j].subcategory) {
                subcategoryIndex = k;
                break;
              }
            }

            if (subcategoryIndex == -1) {
              subcategoryIndex = groupedCategories[categoryIndex].subcategories.length;
              groupedCategories[categoryIndex].subcategories.push({
                name: data[i].questions[j].subcategory,
                questions: []
              });
            }

            groupedCategories[categoryIndex].subcategories[subcategoryIndex].questions.push(data[i].questions[j]);
          }

          setCategories(groupedCategories);

          fetch(ventrisConfig.url + '/api/test/' + routeParams.testId, {
            method: 'get',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
            }
          }).then(function (response) {
            return response.json();
          }).then(async function (data) {
            let answersUpdated: any = {};
            let textAnswersUpdated: any = {};
            let correctAnswersUpdated = 0;
            let incorrectAnswersUpdated = 0;
            let blankAnswersUpdated = 0;
            let domainsUpdated: any = {};

            for (var i = 0; i < data.test?.answers?.length; i++) {
              answersUpdated[data.test.answers[i].test_schema_question_id] = data.test.answers[i].test_schema_question_answer_id;
              textAnswersUpdated[data.test.answers[i].test_schema_question_id] = data.test.answers[i].text_answer;
              if (!domainsUpdated[data.test.answers[i].question?.category]) {
                domainsUpdated[data.test.answers[i].question?.category] = {
                  domain: data.test.answers[i].question?.category,
                  score: 0
                }
              }

              if (data.test.answers[i].test_schema_question_answer_id == 0 && data.test.answers[i].question?.answers?.length == 0) {
                let score = parseInt(data.test.answers[i].text_answer);
                if (!isNaN(score)) {
                  domainsUpdated[data.test.answers[i].question?.category].score += score;
                }
              }

              for (var j = 0; j < rawQuestions?.length; j++) {
                if (data.test.answers[i].test_schema_question_id == rawQuestions[j].id) {
                  for (var k = 0; k < rawQuestions[j].answers?.length; k++) {
                    if (rawQuestions[j].answers[k].id == data.test.answers[i].test_schema_question_answer_id) {
                      if (rawQuestions[j].answers[k].correct == 1) {
                        correctAnswersUpdated++;
                      } else {
                        incorrectAnswersUpdated++;
                      }
                      domainsUpdated[data.test.answers[i].question?.category].score += rawQuestions[j].answers[k].score;
                    }
                  }
                }
              }
            }

            blankAnswersUpdated = rawQuestions.length - correctAnswersUpdated - incorrectAnswersUpdated;

            setTest(data.test);
            setAnswers(answersUpdated);
            setTextAnswers(textAnswersUpdated);
            setCorrectAnswers(correctAnswersUpdated);
            setIncorrectAnswers(incorrectAnswersUpdated);
            setBlankAnswers(blankAnswersUpdated);
            setDomains(domainsUpdated);
          });

          break;
        }
      }
    });
  }, []);

  const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  const answerLetterForOrderNumber = (orderNum: number) => {
    if (orderNum == 1) {
      return "a";
    } else if (orderNum == 2) {
      return "b";
    } else if (orderNum == 3) {
      return "c";
    } else if (orderNum == 4) {
      return "d";
    } else if (orderNum == 5) {
      return "e";
    } else if (orderNum == 6) {
      return "f";
    } else if (orderNum == 7) {
      return "g";
    } else if (orderNum == 8) {
      return "h";
    } else if (orderNum == 9) {
      return "i";
    } else if (orderNum == 10) {
      return "j";
    }

    return "z";
  }

  const answerQuestion = (question: any, answer: any) => {
    let answersUpdated: any = answers;
    answersUpdated[question.id] = answer.id;
    setAnswers(answersUpdated);
    setCardKey(uuidv4());

    let token = sessionStorage.getItem("token");
    fetch(ventrisConfig.url + '/api/test/' + routeParams.testId + '/answer', {
      method: 'post',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        answers: JSON.stringify(answersUpdated),
        text_answers: JSON.stringify(textAnswers)
      })
    }).then(function (response) {
      return response.json();
    }).then(async function (data) {
      console.log(data);
    });
  }

  const updateTextAnswer = (question: any, answer: any) => {
    let answersUpdated: any = textAnswers;
    answersUpdated[question.id] = answer;
    setTextAnswers(answersUpdated);

    let token = sessionStorage.getItem("token");
    fetch(ventrisConfig.url + '/api/test/' + routeParams.testId + '/answer', {
      method: 'post',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        answers: JSON.stringify(answers),
        text_answers: JSON.stringify(answersUpdated)
      })
    }).then(function (response) {
      return response.json();
    }).then(async function (data) {
      console.log(data);
    });
  }

  const completeTest = () => {
    let token = sessionStorage.getItem("token");
    fetch(ventrisConfig.url + '/api/test/' + routeParams.testId, {
      method: 'put',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        name: test.name,
        code: test.code,
        status: "Complete"
      })
    }).then(function (response) {
      return response.json();
    }).then(async function (data) {
      console.log(data);
      document.location.href = "/tests";
    });
  }

  const thetaValueForAnswer = (answer: any, theta: any) => {
    for (let i = 0; i < answer.theta?.length; i++) {
      if (answer.theta[i].theta == theta) {
        return answer.theta[i].p;
      }
    }
    return 0;
  }

  const likelihoodValueForTheta = (theta: any) => {
    for (let i = 0; i < test.likelihood?.length; i++) {
      if (test.likelihood[i].domain == showCalculations) {
        for (let j = 0; j < test.likelihood[i].thetas?.length; j++) {
          if (test.likelihood[i].thetas[j].theta == theta) {
            return test.likelihood[i].thetas[j].likelihood;
          }
        }
      }
    }
    return 0;
  }

  return (
    <Container fluid>
      <Sidebar portal={sessionStorage.getItem("portal")!} page="tests" />
      <Row>
        <Col className="main-content">
            <h2 className="page-header">{routeParams.schema} Test</h2>
            {test.status === "Incomplete" ?
            <Card className="content-card" key={cardKey}>
              {categories.map((category: any) => 
                <>
                  <h3 className="test-category">{category.name}</h3>
                  {category.subcategories?.map((subcategory: any) => 
                    <>
                      {subcategory.name != '' ? <h4 className="test-subcategory">{subcategory.name}</h4> : null }
                      {subcategory.questions?.map((question: any) => 
                        <>
                          <div className="test-question">{question.order_num}. <span dangerouslySetInnerHTML={{__html: question.question}}></span></div>
                          <Form.Control as="textarea" rows={1} className="test-answer-textarea" defaultValue={textAnswers[question.id]} onChange={(event) => updateTextAnswer(question, event.target.value)} />
                          {question.answers?.map((answer: any) => 
                            <button className={answers[question.id] == answer.id ? "test-answer-selected": "test-answer"} onClick={() => answerQuestion(question, answer)}>
                              {(answer.answer == "A" || answer.answer == "B" || answer.answer == "C" || answer.answer == "NR") ?
                              <>
                                <div className={answer.correct ? 'test-answer-correct' : 'test-answer-incorrect'}><span dangerouslySetInnerHTML={{__html: answer.answer}}></span></div>
                                <div className="test-answer-caption" dangerouslySetInnerHTML={{__html: answer.caption}}></div>
                              </> :
                              <>
                                <div className={answer.correct ? 'test-answer-correct' : 'test-answer-incorrect'}>{answerLetterForOrderNumber(answer.order_num)}. <span dangerouslySetInnerHTML={{__html: answer.answer}}></span></div>
                                <div className="test-answer-caption" dangerouslySetInnerHTML={{__html: answer.caption}}></div>
                              </> }
                            </button>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              <br /><br />
              <Button onClick={() => completeTest()}>Complete Test</Button>
              <br /><br />
            </Card> : null }
            {test.status === "Complete" ?
            <Card className="content-card" key={cardKey}>
              This test has been completed!
              <br /><br />
              <Table bordered>
                <thead>
                  <tr>
                    <th className="td-center">Correct Answers</th>
                    <th className="td-center">Incorrect Answers</th>
                    <th className="td-center">Blank Answers</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="td-center">{correctAnswers}</td>
                    <td className="td-center">{incorrectAnswers}</td>
                    <td className="td-center">{blankAnswers}</td>
                  </tr>
                </tbody>
              </Table>
              <br />
              {routeParams.schema === 'DELV' ?
              <Table bordered>
                <thead>
                  <tr>
                    <th className="td-center">Domain</th>
                    <th className="td-center">Theta Score</th>
                    <th className="td-center"></th>
                  </tr>
                </thead>
                <tbody>
                  {test?.max_likelihood?.map((domain: any) => 
                    <tr>
                      <td className="td-center">{domain.domain}</td>
                      <td className="td-center">{domain.theta.toFixed(2)}</td>
                      <td className="td-center"><Button variant="link" onClick={() => setShowCalculations(domain.domain)}>Show Calculations</Button></td>
                    </tr>
                  )}
                </tbody>
              </Table> : 
              <Table bordered>
                <thead>
                  <tr>
                    <th className="td-center">Domain</th>
                    <th className="td-center">Score</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(domains).map((domain: any) => 
                    <tr>
                      <td className="td-center">{domain}</td>
                      <td className="td-center">{domains[domain].score}</td>
                    </tr>
                  )}
                </tbody>
              </Table> }
              {showCalculations != "" ?
              <>
                <br />
                <h4>Calculations: {showCalculations}</h4>
                <div className="calculations-wrapper">
                  <Table size="sm" bordered>
                    <thead>
                      <tr>
                        <th></th>
                        {test?.sorted_answers?.map((answer: any) =>
                          <>
                            {answer.question?.category == showCalculations ? <th>{answer.question?.order_num}</th> : null}
                          </>
                        )}
                        <th>Likelihood</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Discrimination</td>
                        {test?.sorted_answers?.map((answer: any) =>
                          <>
                            {answer.question?.category == showCalculations ? <td>{answer.question?.discrimination}</td> : null}
                          </>
                        )}
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Difficulty</td>
                        {test?.sorted_answers?.map((answer: any) =>
                          <>
                            {answer.question?.category == showCalculations ? <td>{answer.question?.difficulty}</td> : null}
                          </>
                        )}
                        <td></td>
                      </tr>
                      <tr>
                        <td>Pseudo-Guessing</td>
                        {test?.sorted_answers?.map((answer: any) =>
                          <>
                            {answer.question?.category == showCalculations ? <td>0</td> : null}
                          </>
                        )}
                        <td></td>
                      </tr>
                      <tr>
                        <td>Reponses</td>
                        {test?.sorted_answers?.map((answer: any) =>
                          <>
                            {answer.question?.category == showCalculations ? <td>{answer.answer?.correct}</td> : null}
                          </>
                        )}
                        <td></td>
                      </tr>
                      <tr>
                        <td>IRT</td>
                        {test?.sorted_answers?.map((answer: any) =>
                          <>
                            {answer.question?.category == showCalculations ? <td>{answer.irt}</td> : null}
                          </>
                        )}
                        <td></td>
                      </tr>
                      {test?.sorted_answers[0].theta?.map((theta: any) => 
                        <tr>
                          <td>Theta: {theta.theta?.toFixed(2)}</td>
                          {test?.sorted_answers?.map((answer: any) =>
                            <>
                              {answer.question?.category == showCalculations ? <td>{thetaValueForAnswer(answer, theta.theta)}</td> : null}
                            </>
                          )}
                          <td>{likelihoodValueForTheta(theta.theta)}</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <br />
                  <Table size="sm" bordered>
                    <thead>
                      <tr>
                        <th>Theta</th>
                        <th>Maximum Likelihood</th>
                        <th>Maximum Likelihood Theta Value</th>
                        <th>TIF</th>
                        <th>SE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>0.58</td>
                        <td>
                          {test?.max_likelihood?.map((likelihood: any) =>
                            <>
                              {likelihood.domain == showCalculations ? likelihood.likelihood : null}
                            </>
                          )}
                        </td>
                        <td>
                          {test?.max_likelihood?.map((likelihood: any) =>
                            <>
                              {likelihood.domain == showCalculations ? likelihood.theta?.toFixed(2) : null}
                            </>
                          )}
                        </td>
                        <td>
                          {test?.max_likelihood?.map((likelihood: any) =>
                            <>
                              {likelihood.domain == showCalculations ? likelihood.tif : null}
                            </>
                          )}
                        </td>
                        <td>
                          {test?.max_likelihood?.map((likelihood: any) =>
                            <>
                              {likelihood.domain == showCalculations ? likelihood.se : null}
                            </>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </> : null }
            </Card> : null }
        </Col>
      </Row>
    </Container>
  );
}

export default Test;
