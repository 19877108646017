import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Sidebar from '../../components/Sidebar';
import { ventrisConfig } from '../../ventris.config';

type ClinicianHomeProps = {
};
  
function ClinicianHome({}: ClinicianHomeProps) {
  const [user, setUser] = useState([] as any);

  useEffect(() => {
    let token = sessionStorage.getItem("token");
    let currentUser = sessionStorage.getItem("current_user");
    setUser(JSON.parse(currentUser!));
  }, []);

  const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  const startNewTest = (id: number, name: string) => {
    let token = sessionStorage.getItem("token");
    fetch(ventrisConfig.url + '/api/test', {
      method: 'post',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        name: name,
        code: uuidv4(),
        status: "Incomplete",
        test_schema_id: id
      })
    }).then(function (response) {
      return response.json();
    }).then(async function (data) {
      document.location.href = "/test/" + name + "/" + data.test.id;
    });
  }

  return (
    <Container fluid>
      <Sidebar portal="clinician" page="home" />
      <Row>
        <Col className="main-content">
            <h2 className="page-header">Home</h2>
            <div className="clinician-home-content">
              <h4>Welcome, {user.first_name}!</h4>
              <br />
              <Button variant="secondary" onClick={() => startNewTest(1, 'DELV')}>Start New DELV Test</Button>
              <br /><br />
              <Button variant="secondary" onClick={() => startNewTest(2, 'ALL')}>Start New ALL Test</Button>
              <br /><br />
              <Link to="/tests" className="btn btn-secondary">Load Incomplete Test</Link>
              <br /><br />
              <Link to="/tests" className="btn btn-secondary">View Scored Test</Link>
            </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ClinicianHome;
