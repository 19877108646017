import { useState, useEffect } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Sidebar from '../../components/Sidebar';
import { ventrisConfig } from '../../ventris.config';

import deleteIcon from '../../assets/svg/delete.svg';

type ClinicianTestsProps = {
};
  
function ClinicianTests({}: ClinicianTestsProps) {
  const [tests, setTests] = useState([] as any);
  const [selectedTestId, setSelectedTestId] = useState("" as any);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    let token = sessionStorage.getItem("token");
    fetch(ventrisConfig.url + '/api/tests', {
      method: 'get',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
      }
    }).then(function (response) {
      return response.json();
    }).then(async function (data) {
      setTests(data);
    });
  }, []);

  const loadTest = (name: any, testId: any) => {
    document.location.href = "/test/" + name + "/" + testId;
  }

  const deleteTest = (testId: any) => {
    setSelectedTestId(testId);
    setShowDeleteModal(true);
  }

  const actuallyDeleteTest = () => {
    let token = sessionStorage.getItem("token");
    fetch(ventrisConfig.url + '/api/test/' + selectedTestId, {
      method: 'delete',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
      }
    }).then(function (response) {
      return response.json();
    }).then(async function (data) {
      fetch(ventrisConfig.url + '/api/tests', {
        method: 'get',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
        }
      }).then(function (response) {
        return response.json();
      }).then(async function (data) {
        setTests(data);
        setShowDeleteModal(false);
      });
    });
  }

  const actionFormatter = (cell: any, row: any) => {
    return (
      <div>
        <Button variant="secondary" size="sm" className="action-btn" onClick={() => loadTest(row.name, row.id)}>{row.status === "Complete" ? "View Results" : "Load Test"}</Button>
        {row.status === "Incomplete" ? <Button variant="link" size="sm" onClick={() => deleteTest(row.id)}><img src={deleteIcon} alt="Delete Test" /></Button> : null}
      </div>
    );
  }

  const columns = [
    {
      dataField: 'name',
      text: 'Test',
      sort: true
    }, {
      dataField: 'code',
      text: 'Code',
      sort: true
    }, {
      dataField: 'status',
      text: 'Status',
      sort: true
    }, {
      dataField: 'id',
      text: 'Actions',
      formatter: actionFormatter
    }
  ];

  return (
    <Container fluid>
      <Sidebar portal="clinician" page="tests" />
      <Row>
        <Col className="main-content">
            <h2 className="page-header">My Tests</h2>
            <Card className="content-card">
              <BootstrapTable keyField='id' data={tests} columns={columns} bordered={false} pagination={paginationFactory({})} />
            </Card>
        </Col>
      </Row>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Test</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete the selected test?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button variant="primary" onClick={() => actuallyDeleteTest()}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default ClinicianTests;
